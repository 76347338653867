import { ansiSpeciesList } from '@edm-internationa/d-calc-calculations';
import { Button, KIND } from "baseui/button";
import { Cell, Grid } from "baseui/layout-grid";
import {
    Modal,
    ModalBody,
    ModalButton, ModalFooter, ModalHeader
} from 'baseui/modal';
import { Tab, Tabs } from "baseui/tabs-motion";
import { PLACEMENT, ToasterContainer, toaster } from "baseui/toast";
import { H2, Label3 } from "baseui/typography";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';
import { calculateSlice, printPole, setDamageParameters, setInfo, setProjectId } from "../../actions/pole";
import { addPole, savePole, setCurrentPoleWithoutSaving } from '../../actions/poles';
import { closeModal, newPole, openModal, savePoleTrigger, setProject } from "../../actions/ui";
import { ModalOverride, ToasterContainerOverride } from '../../theming';
import useIsMobile from '../../utils/useIsMobile';
import { ConnectedPoleSelection } from "../PoleSelection";
import DamageEditor from "../inputs/DamageEditor";
import DamagePreview from "../inputs/DamagePreview";
import ImportPole from '../inputs/ImportPole';
import { Page } from "../layout/Page";
import { Spacer } from "../layout/Spacer";
import { ROUTE_DATABASE } from '../navigation/Sidebar';
import { validateANSIParameters } from "./ANSIData";
import { ConnectedAnalyzeTab } from "./AnalysisTab";
import { ExportButton } from "./ExportButton";
import PoleInfo from "./PoleInfo";
import { ConnectedANSIInfo } from "./dimensions/ANSIInfo";
import SaveButton from "./header/SaveButton";
import returnURL from './returnURL';


const TOAST_OVERRIDES = {
  overrides: {
    InnerContainer: {
      style: {
        width: "100%",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }
    }
  }
}
export const CompactH2 = ({ children }) => <H2 marginTop="5px" marginBottom="5px">
  {children}
</H2>;

export const FixedButton = ({ children, ...rest }) => {
  const isMobile = useIsMobile();
  return <Button kind='secondary' {...rest} overrides={{
    Root:
    {
      style: {
        width: isMobile ? '100px' : 'auto',
        marginLeft: isMobile ? '10px' : 'auto',
        marginRight: isMobile ? '10px' : 'auto',
        marginTop: isMobile ? '10px' : 'auto',
        marginBottom: isMobile ? '10px' : 'auto'
      }
    }
  }}>{children}</Button>;
}

export const AssessDamage = ({ completeReturnURL, calculateSlice, savePoleTrigger, currentLevel, newPole, isPoleSelected, printPole, projectId, setCurrentPoleWithoutSaving, modal, closeModal, poleParamsDetailsData, setInfo, setProject, setDamageParameters, setProjectId }) => {
  const [activeKey, setActiveKey] = React.useState("0");
  const [toastKey, setToastKey] = React.useState(null);
  const [isImportModalOpen, setIsImportModalOpen] = React.useState(false);
  const [returnURLForThirdApp, setReturnURLForThirdApp] = React.useState("");
  const history = useHistory();
  useEffect(() => {
    if (Object.keys(poleParamsDetailsData).length > 0) {
      console.log('value of view vs create', poleParamsDetailsData?.viewVsCreate);
      if (poleParamsDetailsData?.viewVsCreate == 'view') {
        if (poleParamsDetailsData?.returnUrlParams !== null) {
          let url = poleParamsDetailsData?.returnUrlParams;
          console.log('return url', url);
          setReturnURLForThirdApp(url);
        }
      }
      if (poleParamsDetailsData?.viewVsCreate == 'create') {
        if (poleParamsDetailsData?.returnUrlParams !== null ||
          poleParamsDetailsData?.poleIdParams !== null ||
          poleParamsDetailsData?.diameterParams !== null ||
          poleParamsDetailsData?.DistGL !== null ||
          poleParamsDetailsData?.Circumference !== null ||
          poleParamsDetailsData?.description !== null ||
          poleParamsDetailsData?.Species !== null ||
          poleParamsDetailsData?.Class !== null ||
          poleParamsDetailsData?.Length !== null ||
          poleParamsDetailsData?.poleName !== null ||
          poleParamsDetailsData?.projectName !== null) {
          setCurrentPoleWithoutSaving();
  
          let setObj = { description: "Pole ID: " + poleParamsDetailsData?.poleIdParams + ", " + poleParamsDetailsData?.description };
          if (poleParamsDetailsData?.poleIdParams) {
            setObj.id = poleParamsDetailsData.poleIdParams;
          }
  
          if (poleParamsDetailsData?.poleName) {
            setObj.name = poleParamsDetailsData.poleName;
          }
  
          setInfo(setObj);
          setProject(poleParamsDetailsData?.projectName, null);
          setProjectId(poleParamsDetailsData?.projectId)
          setDamageParameters(currentLevel, { distanceFromGround: parseFloat(poleParamsDetailsData?.DistGL) })
  
          if (poleParamsDetailsData?.diameterParams !== null) {
            setDamageParameters(currentLevel, { diameter: parseFloat(poleParamsDetailsData?.diameterParams) })
          }
          if (poleParamsDetailsData?.Circumference !== null) {
            setDamageParameters(currentLevel, { diameter: parseFloat(poleParamsDetailsData?.Circumference) / Math.PI })
          }
  
          if (poleParamsDetailsData?.Species || poleParamsDetailsData?.Class || poleParamsDetailsData?.Length) {
            let ansiSpeciesElement = ansiSpeciesList.filter((item, index) => item.label == poleParamsDetailsData?.Species);
            if (ansiSpeciesElement.length > 0) {
              try {
                validateANSIParameters({ ansiSpecies: ansiSpeciesElement[0]?.label, ansiClass: poleParamsDetailsData?.Class, ansiLength: parseFloat(poleParamsDetailsData?.Length) })
                setInfo({ ansiSpecies: { id: ansiSpeciesElement[0]?.id, name: ansiSpeciesElement[0]?.label }, ansiClass: poleParamsDetailsData?.Class, ansiLength: parseFloat(poleParamsDetailsData?.Length) })
              } catch (e) {
                toaster.negative(e, TOAST_OVERRIDES);
              }
            } else {
              toaster.negative('Could not determine species!', TOAST_OVERRIDES);
            }
          }
  
          if (poleParamsDetailsData?.returnUrlParams !== null) {
            let url = poleParamsDetailsData?.returnUrlParams;
            setReturnURLForThirdApp(url);
          }
        }
      }
    }
  }, [poleParamsDetailsData])

  const switchTabs = (key) => {
    if (key === '1') {
      calculateSlice(currentLevel);
    }
    setActiveKey(key)
  }

  const DamageEditorTabs = () => {
    if (!isPoleSelected) return <ConnectedPoleSelection />

    return <Tabs
      activeKey={activeKey}
      onChange={({ activeKey }) => switchTabs(activeKey)}
      activateOnFocus
      fill="fixed"
      overrides={{
        Root: {
          style: {
            maxWidth: '100%',
            width: '95%',
            margin: 'auto'
          }
        }
      }}
    >
      <Tab title={<Label3> Damage Editor </Label3>}>

        <Grid gridColumns={10} gridMargins={0} behavior={"fluid"}>
          <Cell span={[10, 10, 5, 4]}>
            <div style={{ maxWidth: '750px' }}>
              <DamageEditor />
            </div>
          </Cell>
          <DamagePreview />

        </Grid>
      </Tab>
      <Tab title={<Label3>Analyze</Label3>}>
        <ConnectedAnalyzeTab />
      </Tab>
      <Tab title={<Label3>Pole Info</Label3>}>
        <Grid gridColumns={10}>
          <Cell span={[10, 10, 5]}>
            <PoleInfo />
          </Cell>
          <Cell span={[10, 10, 5]}>
            <ConnectedANSIInfo />
          </Cell>

        </Grid>
      </Tab>
    </Tabs>
  }

  const handleSelectClick = () => {
    if (projectId) {
      savePoleTrigger((status) => {
        if (status === 'success') {
          history.push(ROUTE_DATABASE)
        } else {
          toaster.negative('Something went wrong.', TOAST_OVERRIDES);
        }
      })
    } else {
      history.push(ROUTE_DATABASE)
    }
  }

  const handleSavePole = () => {
    savePoleTrigger((status) => {
      if (status === 'success') {
        toaster.positive('Saved Pole', TOAST_OVERRIDES)
      } else {
        toaster.negative('Something went wrong.', TOAST_OVERRIDES);
      }
    });
  }
  return (
    <Page
      title='Model Damage'
      buttons={isPoleSelected && [
        <Button key="New" onClick={() => newPole(() => setActiveKey('0'))}>New</Button>,
        <Button key="Select" onClick={handleSelectClick}>Select</Button>,
        <Button
          onClick={() => {
            handleSavePole();
          }}>Save
        </Button>,

        <Button kind='secondary' onClick={() => setIsImportModalOpen(true)}>
          Import
        </Button>,
        <ExportButton type='json' key="export" />,
        <Button key="Print"
          onClick={async () => {
            if (isPoleSelected) {
              printPole(() => setToastKey(toaster.negative('Something went wrong, please try again later.')))
              toaster.clear(toastKey);
              setToastKey(null);
            }
          }
          }>
          Print
        </Button>,
        <>
          {returnURLForThirdApp && (
            <Button key="Send Data" style={{ backgroundColor: "rgb(238, 238, 238)", color: "#000000" }} onClick={() => window.location.href = returnURL(returnURLForThirdApp)}>
              Send Data
            </Button>
          )}
        </>,
      ]}>
      <ImportPole isOpen={isImportModalOpen} setOpen={setIsImportModalOpen} />
      <SaveButton isOpen={modal && modal.type === 'projectNeeded'} setOpen={() => closeModal()} />
      <ToasterContainer overrides={ToasterContainerOverride} placement={PLACEMENT.bottomLeft} autoHideDuration={5000}></ToasterContainer>
      <DamageEditorTabs />
      <Modal overrides={ModalOverride} onClose={() => closeModal()} isOpen={!!modal && (modal.type === 'unsavedWork' || modal.type === 'diameterNeeded')} unstable_ModalBackdropScroll>
        {modal && modal.type === 'unsavedWork' ? <ModalHeader>Are you sure you want to proceed?</ModalHeader> : <ModalHeader>Cannot save this pole</ModalHeader>}
        {modal && modal.type === 'unsavedWork' ?
          <ModalBody>
            This pole is not associated with a project, and your data won't be saved.
            <Spacer />
            To keep your data, please save this pole before starting a new one.
          </ModalBody>
          :
          <ModalBody>
            This pole does not have a diameter. Please enter one before proceeding.
            <Spacer />

          </ModalBody>}
        {modal && modal.type === 'unsavedWork' ?
          <ModalFooter>
            <ModalButton onClick={() => closeModal()} kind={KIND.tertiary}>Cancel</ModalButton>
            <ModalButton onClick={() => {
              setCurrentPoleWithoutSaving();
              setActiveKey("0");
              closeModal();
            }}>Continue Anyway</ModalButton></ModalFooter>
          :
          <ModalFooter><ModalButton onClick={() => closeModal()} kind={KIND.tertiary}>Back</ModalButton></ModalFooter>}

      </Modal>
      <ErrorModal setErrorModalOpen={() => closeModal()} errorModalOpen={!!modal && (modal.type === 'diameter' || modal.type === 'damages')} type={modal && modal.type} />
    </Page>
  );
};

const ErrorModal = ({ setErrorModalOpen, errorModalOpen, type }) => {
  return <Modal overrides={ModalOverride} onClose={() => setErrorModalOpen(false)} isOpen={errorModalOpen} unstable_ModalBackdropScroll>
    <ModalHeader>Error</ModalHeader>
    <ModalBody>
      {type === 'diameter' ? "This pole doesn't have enough data to save." : "There are errors with the damages"}
      <Spacer />
      {type === 'diameter' ? "Please enter a diameter or circumference." : "Please correct the data errors."}
    </ModalBody>
    <ModalFooter>
      <ModalButton onClick={() => {
        setErrorModalOpen(false);
      }}>Back to Editing</ModalButton>
    </ModalFooter>
  </Modal>
}

export const ConnectedAssess = connect(
  ({ ui, pole, user }) => {
    return {
      currentLevel: ui.currentLevel,
      modal: ui.modal,
      isPoleSelected: 'id' in pole.info,
      projectId: pole.projectId,
      poleParamsDetailsData: user.poleParamsDetails,
      completeReturnURL: user?.returnURL
    }
  },
  {
    calculateSlice,
    addPole,
    savePole,
    savePoleTrigger,
    printPole,
    setCurrentPoleWithoutSaving,
    closeModal,
    openModal,
    newPole,
    setInfo,
    setProject,
    setDamageParameters,
    setProjectId
  }
)(AssessDamage);
